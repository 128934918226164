import httpClient from "./httpClient";

export function getParams() {
  return httpClient.get("/reports/params", { params: {} });
}

export function getRounds(filter) {
  return httpClient.get("/reports", {
    params: { ...filter },
  });
}
