<template>
  <v-row
    align="center"
    justify="center">
    <v-col
      cols="12">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          {{ $t('views.reports.rounds.title') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="3">
              <date-picker
                :value.sync="filters.date"
                :label="$t('fields.date')"
                dense
                outlined />
            </v-col>
            <v-col
              cols="12"
              md="3">
              <v-autocomplete
                v-model="filters.bettingPoolId"
                :items="bettingPools"
                item-text="label"
                item-value="value"
                :label="$t('fields.betting_pools')"
                dense
                outlined />
            </v-col>
          </v-row>
          <v-row
            class="px-3"
            justify="end"
            align="center">
            <v-btn
              color="success"
              @click="getReport">
              {{ $t("actions.filter") }}
            </v-btn>
          </v-row>
          <br>
          <data-table
            class="stripped"
            dense
            :loading="loading"
            :headers="tableColumns"
            :items="rounds" />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { getParams, getRounds } from "@/api/report";
import ContentLoader from "../../mixins/ContentLoader";
import DatePicker from "../../components/inputs/DatePicker";
import moment from "moment";
import { i18n } from "@/i18n";
import DataTable from "@/components/DataTable.vue";

function data() {
  return {
    rounds: [],
    bettingPools: [],
    filters: {
      date: moment().format('YYYY-MM-DD'),
      bettingPoolId: null
    },
  };
}
export default {
  name: "Round",
  components: {
    DataTable,
    DatePicker,
  },
	mixins: [ContentLoader],
  data,
  computed: {
    tableColumns() {
      return [
        {
          value: "sequence",
          text: i18n.t("views.reports.rounds.tableColumns.roundId"),
        },
        {
          value: "balance",
          text: i18n.t("views.reports.rounds.tableColumns.rouletteBalance"),
        },
        {
          value: "totalSold",
          text: i18n.t("views.reports.rounds.tableColumns.totalSold")
        },
        {
          value: "totalPrizes",
          text: i18n.t("views.reports.rounds.tableColumns.totalPaid")
        },
        {
          value: "result",
          text: i18n.t("views.reports.rounds.tableColumns.winningNumber")
        },
      ];
    },
  },
  created() {
    getParams().then(response => {
      this.bettingPools = response.data.bettingPools;
    })
  },
  methods: {
    getReport() {
      getRounds(this.filters).then(response => {
        this.rounds = response.data.rounds;
      })
    }
  }
};
</script>

<style scoped>

</style>
